.signupPage{
    padding-top: 50px;
}

.signupForm {
    position: relative;
    padding-top: 30px;
    &__closeBtn {
        position: absolute;
        top: 15px;
        left: 20px;
        img {
            width: 12px;
        }
    }
    &__title {
        text-align: center;
        h3 {
            font-family: "Poppins", sans-serif;
            font-style: normal;
            font-weight: 700;
            font-size: 30px;
            letter-spacing: -0.05em;
            color: #000000;
            margin: 0px;
        }
        p {
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 400;
            font-size: 15px;
            line-height: 10px;
            text-align: center;
            color: #797979;
        }
    }
    &__form {
        margin: 0 auto;
        padding-top: 40px;
    }
}

.inputFormElement{
    width: 70%;
    margin: 20px auto;
    label {
        color: #6369D1;
        font-family: 'Roboto', sans-serif;
        font-weight: bold;
        font-size: 14px;
    }
    .inputBox {
        margin-top: 8px;
        width: 100%;
        display: block;
        min-height: 30px;
        background: #FFFFFF;
        border: 0.5px solid #D5D5D5;
        border-radius: 3px;
        padding: 10px;
        font-family: 'Roboto';
        font-weight: bold;
        color:#333;
    }
}

.signupBtn {
    padding-top: 30px;
    button {
        margin: 0px auto;
        display: block;
        width: 70%;
        background: #6369D1;
        border-radius: 8px;
        color: white;
        font-family: 'Poppins', sans-serif;
        font-weight: bold;
        border: none;
        font-size: 16px;
        padding: 8px 15px;
        height: 50px;
    }
}