.login {
    margin-top: 50px;
    position: relative;

}

.closeBtn {
    position: absolute;
    width: 500px;
    padding-left: 24px;
    padding-top: 16px;
}

.title{
    text-align: center;
    padding-top: 30px;
        h3 {
            font-family: "Poppins", sans-serif;
            font-style: normal;
            font-weight: 700;
            font-size: 30px;
            letter-spacing: -0.05em;
            color: #000000;
            margin: 0px;
        }
        p {
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 400;
            font-size: 15px;
            line-height: 10px;
            text-align: center;
            color: #797979;
            margin-top: 9px;
        }
}

.walletOptions {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 12px;
    padding-top: 50px;
}

.wallet {
    display: flex;
    justify-content: center;
    button {
        max-width: 280px;
        min-height: 55px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        border: 1px solid #dedede;
        background-color: white;
        width: 100%;
        border-radius: 8px;
        padding: 0 16px;
        h3 {
            font-family: 'Roboto';
            font-weight: bold;
            font-size: 14px;
            text-align: center;
            width: 100%;
        }
    }
}

.disclaimer {
    position: absolute;
    bottom: 0;
    width: 500px;
    padding-bottom: 20px;
    p {
        color: #8e8e8e;
        text-align: center;
        font-size: 12px;
        a {
            color: #6369d1;
        }
    }
}