.profileStatsMain{
    .container  {
    display: flex;
    flex-direction: column;
    
    .dp {
        text-align: center;
        img {
            width: 140px;
            border-radius: 100px;
            margin: 20px auto;
            display: block;
        }
        h3{
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 700;
            font-size: 26px;
            line-height: 116.5%;
            /* or 17px */

            letter-spacing: -0.05em;

            color: #000000;
            margin-bottom: 5px;

        }
        p{
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            line-height: 116.5%;
            /* identical to box height, or 12px */

            letter-spacing: -0.05em;

            color: #797979;
            margin: 0;
            width: 65%;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            margin: 0 auto;

        }
    }
    .stats {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 30px;
        padding-bottom: 30px;
        gap: 18px;
        .stat {
            display: flex;
            flex-direction: row;
            justify-content: center;
            width: 80%;
            .titleName {
                width: 50%;
                h3{
                    text-align: right;
                    margin: 0;
                    font-family: 'Roboto';
                    font-weight: 700;
                    font-size: 17px;
                    line-height: 116.5%;
                    letter-spacing: -0.01em;
                    color: #6369D1;

                }
            }
            .titleValue {
                width: 49%;
                h4{
                    text-align: center;
                    margin: 0;
                    font-family: 'Roboto';
                    font-style: normal;
                    font-weight: 700;
                    font-size: 17px;
                    line-height: 116.5%;
                    letter-spacing: -0.02em;
                    color: #000000;
                    
                }
            }
            
        }
    }
    }
}