.subscribeCard{
    background-color: #000000ba;
    height: 100vh;
    width: 100vw;
    position: fixed;
    z-index: 10;
}

.blurred {
    filter: blur(8px);
    -webkit-filter: blur(8px);
}