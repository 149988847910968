@import '../../commonScss/commonMixins.scss';

.thankyou {
    padding-top: 50px;
    .thankyouCard {
        min-height: auto;
        .container {
            padding: 24px;
            .title {
                text-align: center;
                display: flex;
                flex-direction: column;
                gap: 8px;
                h2 {
                    margin: 0;
                    font-family: 'Poppins';
                    font-size: 32px;
                }
                h4 {
                    margin: 0;
                    font-family: 'Roboto';
                    font-weight: 500;
                }
            }
            .cta {
                display: flex;
                justify-content: center;
                padding-top: 50px;
                button {
                    @include ctaButton;
                }
                
            }
        }
    }
}
