.profile {
    // max-width: 1064px;
    width: 90%;
    margin: 0 auto;
    .container{
       padding: 40px;
        .header{
            display: flex;
            justify-content: space-between;
            .sectionName{
                display: flex;
                flex-direction: column;
                h3 {
                    font-family: 'Poppins';
                    margin: 0;
                    font-size: 30px;
                }
                p {
                    margin: 0;
                    font-family: 'Roboto';
                    color: #797979;
                    font-weight: bold;
                }
            }
            .stats {
                display: flex;
                gap: 80px;
                .items {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    h3 {
                        font-family: 'Poppins';
                        margin: 0;
                        font-size: 30px;
                    }
                    p {
                        margin: 0;
                        font-family: 'Roboto';
                        color: #797979;
                        font-weight: bold;
                    }
                }
            }
        }
        .body{
            padding-top: 40px;
            display: flex;
            justify-content: flex-end;
            .navigation {
                width: 30%;
                position: relative;
                min-height: 80%;
                h5 {
                    font-size: 18px;
                    font-family: 'Poppins';
                    font-weight: bold;
                    color: #797979;
                    transition: all 0.4s;
                    
                    &:hover {
                        color: black;
                    }
                }
            }
            .settingsForm {
                width: 70%;
                background: #FFFFFF;
                border: 0.5px solid #DEDEDE;
                box-shadow: 2px 8px 10px 1px rgba(0, 0, 0, 0.04);
                border-radius: 6px;
                display: flex;
                padding: 25px;
                justify-content: space-between;
                .dpEditSection{
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    width: 20%;
                    .dpChange {
                        img {
                            width: 150px;
                            margin: 20px auto;
                            display: block;
                            border-radius: 100px;
                            border: 1px solid #DEDEDE;
                        }
                        p {
                            text-align: center;
                            margin-top: 10px;
                            font-family: 'Roboto';
                            color: #797979;
                        }
                    }
                    .socialLinks {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        padding-top: 30px;
                        h5 {
                            font-family: 'Roboto';
                            font-weight: bold;
                            font-size: 14px;
                            margin-bottom: 10px;
                        }
                        input {
                            margin-bottom: 15px;
                            border: 1px solid #E2E2E2;
                            border-radius: 4px;
                            height: 30px;
                            padding: 5px 10px;
                        }
                    }
                }
                .infoEditSection {
                    padding: 10px 15px;
                    width: 45%;
                    .formItem {
                        display: flex;
                        flex-direction: column;
                        margin-bottom: 25px;
                        label {
                            font-family: 'Roboto';
                            font-weight: bold;
                            font-size: 14px;
                            margin-bottom: 8px;
                        }
                        input {
                            background: #FFFFFF;
                            border: 0.5px solid #DEDEDE;
                            border-radius: 6px;
                            height: 30px;
                            width: 300px;
                            
                        }
                        textarea {
                            background: #FFFFFF;
                            border: 0.5px solid #DEDEDE;
                            border-radius: 6px;
                            height: 80px;
                            width: 300px;
                            resize: none;
                        }
                        .emailVerify {
                            button {
                                margin-left: 30px;
                                height: 30px;
                                background: #6369D1;
                                border-radius: 8px;
                                border: none;
                                color: white;
                                font-family: 'Roboto';
                                font-weight: bold;
                                padding: 0px 20px;
                            }
                        }
                    }
                    .saveForm {
                        padding-top: 50px;
                        button {
                            background: #6369D1;
                            border-radius: 6px;
                            font-family: 'Poppins';
                            font-weight: bold;
                            color: white;
                            height: 40px;
                            width: 150px;
                            padding: 4px 20px;
                            border: none;
                        }
                    }
                }
                .manage {
                    width: 30%;
                    button {
                        max-width: 250px;
                        width: 100%;
                        background: #6369D1;
                        border-radius: 6px;
                        font-family: 'Poppins';
                        font-weight: bold;
                        color: white;
                        height: 40px;
                        padding: 4px 20px;
                        border: none;
                        margin: 10px auto;
                        display: block;
                    }
                }
            }
        }

    }
    

}