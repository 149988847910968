.feedCard {
    width: 100%;
    border: 0.5px solid #DEDEDE;
    border-radius: 6px;
    padding: 15px 20px;
    max-width: 800px;
    .posterInfo {
        display: flex;
        flex-direction: row;
        align-items: center;
        .dp {
            img {
                width: 40px;
                border-radius: 50px;
                margin-right: 10px;
            }
        }
        .name {
            h4{
                margin: 0;
                font-family: 'Roboto';
                font-weight: 600;
                font-size: 15px;
                line-height: 116.5%;
                color: #000000;
                margin-bottom: 5px;
            }
            p {
                margin: 0;
                font-family: 'Roboto';
                font-weight: 500;
                font-size: 11px;
                line-height: 116.5%;
                color: #797979;
            }
        }
        .content {
            p{
                font-family: 'Roboto';
                font-size: 12px;
            }
        }
    }
}