.create {
    position: fixed;
    height: 100vh;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.372);
    z-index: 1;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    .container {
        background-color: white;
        max-width: 1060px;
        min-width: 480px;
        min-height: 295px;
        border-radius: 16px;
        padding: 24px 32px;
        .header{
            display: flex;
            justify-content: space-between;
            // padding: 0px 16px;
            position: relative;
            h3 {
                font-family: "Poppins";
                font-size: 20px;
                margin: 0;
            }
            img {
                position: absolute;
                right: 5px;
                width: 14px;
            }
        }
        .inputField {
            padding-top: 30px;
            textarea {
                width: 100%;
                height: 140px;
                border-radius: 8px;
                border: 1px solid #d2d2d2;
                resize: none;
            }
        }
        .submit {
            padding-top: 20px;
            button {
                background: #6369D1;
                border-radius: 6px;
                border: none;
                height: 35px;
                min-width: 100px;
                color: white;
                font-family: 'Roboto';
                font-weight: bold;
            }
        }
    }
}