
@import '../../commonScss/commonMixins.scss';

.landing {
    .hero {
        background: hsla(184, 85%, 63%, 1);
        background: linear-gradient(257deg, #683cc50a 0%, hsl(290deg 46% 76%) 50%, #965aff 100%);
        background: -moz-linear-gradient(257deg, #683cc50a 0%, hsl(290deg 46% 76%) 50%, #965aff 100%);
        background:webkit-linear-gradient(257deg, #683cc50a 0%, hsl(290deg 46% 76%) 50%, #965aff 100%);
        // filter: progid: DXImageTransform.Microsoft.gradient( startColorstr="#4FE6F1", endColorstr="#A6A6DE", GradientType=1 );        
        min-height: 500px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 0 150px;
        color: white;
        h2 {
            margin: 0;
            font-family: 'Poppins';
            font-size: 42px;
        }
        h4 {
            margin: 0;
            font-family: 'Roboto';
            font-size: 19px;
            font-weight: 500;
            line-height: 28px;
        }
        button {
            margin-top: 30px;
            box-shadow: 0px 5px 8px rgba(0, 0, 0, 0.14);
            width: 300px;
            border-radius: 8px;
            border: none;
            height: 46px;
            min-width: 320px;
            color: #636363;
            font-family: 'Poppins';
            font-weight: bold;
            font-size: 16px;
            background-color: white;
            transition: all 0.3s;
            &:hover {
                color: #141414;
                background-color: #efe8ff;
            }
        }
    }
    .container {
        padding: 10px 150px 150px;
        .section {
            padding-top: 80px;
            display: flex;
            flex-direction: column;
            gap: 40px;
            h3 {
                font-size: 30px;
                font-family: 'Poppins';
            }
            p {
                font-family: 'Roboto';
                font-size: 20px;
                line-height: 30px;
            }
            .first {
                height: 350px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                h3 {
                    text-align: center;
                }
                p {
                    text-align: center;
                }
            }
            .second {
                border: 1px solid #dededd;
                padding: 40px 24px 40px;
                border-radius: 16px;
                height: 400px;
                h3 {
                    text-align: center;
                    margin-bottom: 10px;
                }
                p {
                    margin: 0;
                    text-align: center;
                }
                .steps {
                    padding-top: 60px;
                    display: flex;
                    .stepCard {
                        min-height: auto;
                        width: 28%;
                        padding:24px;
                        h4 {
                            text-align: center;
                            margin: 0;
                            margin-bottom: 10px;
                            font-family: 'Poppins';
                            font-size: 20px;
                            color: #6369d1
                        }
                        p {
                            font-size: 18px;
                            margin: 0;
                        }
                    }
                }
            }
            .third {
                padding-top: 120px;
                h3 {
                    text-align: center;
                }
                .image {
                    svg {
                        margin: 0 auto;
                        display: block;
                    }
                }
            }
        } 
    }
    .footer {
        background: hsla(184, 85%, 63%, 1);
        background: linear-gradient(257deg, #683cc50a 0%, hsl(290deg 46% 76%) 50%, #965aff 100%);
        background: -moz-linear-gradient(257deg, #683cc50a 0%, hsl(290deg 46% 76%) 50%, #965aff 100%);
        background:webkit-linear-gradient(257deg, #683cc50a 0%, hsl(290deg 46% 76%) 50%, #965aff 100%);
        // filter: progid: DXImageTransform.Microsoft.gradient( startColorstr="#4FE6F1", endColorstr="#A6A6DE", GradientType=1 );        
        min-height: 300px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 0 150px;
        color: white;
    }
}