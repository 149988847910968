@import '../../commonScss/commonMixins.scss';

.creatorFeed {
    .container {
        display: flex;
        flex-direction: row;
        justify-content: center;
        padding-top: 40px;
        max-width: 100%;
        margin: 0 auto;
        gap: 90px;
        .profileStats {
            min-width: 300px;
        }
        
        .feed{
            position: relative;
            .dialogOverlay{
                position: absolute;
                z-index: 10;
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                .box {
                    background-color: white;
                    width: 350px;
                    min-height: 180px;
                    padding: 24px;
                    border-radius: 16px;
                    border: 0.5px solid #dedede;
                    h4 {
                        text-align: center;
                        font-family: 'Poppins';
                        font-size: 20px;
                    }
                    button {
                        @include ctaButton;
                        margin: 0 auto;
                        display: block;
                    }
                }
            }
            .feedList {
                width: 800px;
                display: flex;
                flex-direction: column;
                gap: 16px;
                justify-content: center;
                align-items: center;
            }
            
        }
        
        .blurred {
            filter: blur(8px);
            -webkit-filter: blur(8px);
        }
    }
}