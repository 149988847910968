.checkout {
    padding-top: 40px;
}

.checkoutCard {
    padding: 24px;
    position: relative;
    .closeBtn {
        position: absolute;
        right: 28px;
        img {
            width: 14px;
        }
    }
    &__header {
        display: flex;
        .dp {
            width: 30%;
            img {
                width: 100px;
                height: 100px;
                border-radius: 50%;
                border: 1px solid #d1d1d1;
                margin: 0 auto;
                display: block;
            }
        }
        .name {
            width: 70%;
            padding-left: 20px;
            padding-top: 15px;
            p {
                margin-bottom: 4px;
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 700;
                font-size: 14px;
                line-height: 116.5%;
                letter-spacing: -0.05em;
                color: #6369D1;

            }
            h4 {
                margin-top: 4px;
                margin-bottom: 8px;
                font-size: 25px;
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 700;
                line-height: 116.5%;
                letter-spacing: -0.05em;
                color: #333;
            }
            h5 {
                margin: 0;
                color: #777777;
                font-family: 'Roboto';
                letter-spacing: -0.03em;
            }
        }
    }
    &__connect {
        padding-top: 50px;
        h4 {
            text-align: center;
            font-family: 'Roboto';
        }
        .wallet {
            display: flex;
            justify-content: center;
            button {
                max-width: 280px;
                min-height: 55px;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                border: 1px solid #dedede;
                background-color: white;
                width: 100%;
                border-radius: 8px;
                padding: 0 16px;
                h3 {
                    font-family: 'Roboto';
                    font-weight: bold;
                    font-size: 14px;
                    text-align: center;
                    width: 100%;
                }
                &:hover {
                    background-color:#ededed;
                }
            }

        }
    }
    &__form {
        display: flex;
        flex-direction: column;
        padding-top: 35px;
        .formItem {
            margin-top: 10px;
            display: flex;
            flex-direction: column;
            margin: 10px auto;
            min-height: 95px;
            width: 90%;
            input {
                margin-top: 5px;
                height: 35px;
                background: #FFFFFF;
                border: 0.5px solid #E1E1E1;
                border-radius: 5px;
                padding: 5px 10px;
                font-size: 14px;
                font-family: 'Poppins';
                font-weight: bold;
            }
            label {
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 700;
                font-size: 14px;
                line-height: 116.5%;
                letter-spacing: -0.03em;
                color: #6369D1;
            }
            p {
                font-family: 'Roboto';
                margin: 0;
                font-size: 14px;
                margin-top: 10px;
                color: #e21111;
            }
            .balance {
                h6 {
                    margin: 0;
                    margin-top: 8px;
                    font-family: 'Roboto';
                }
                span {
                    color: #6369D1;
                }
            }
            .amountField {
                display: flex;
                input {
                    width: 100%;
                }
                .currency{
                    border: 1px solid #d1d1d1;
                    height: 44px;
                    margin-top: 6px;
                    display: flex;
                    align-items: center;
                    padding: 0px 10px;
                    font-family: 'Poppins';
                    font-weight: bold;
                    margin-left: 10px;
                    border-radius: 6px;
                }
            }
            .currency {
                select {
                    border: none;
                    font-size: 16px;
                    font-family: 'Roboto';
                    outline: none;
                }           
            }
            .frequency{
                display: flex;
                font-family: "Roboto";
                justify-content: space-between;
                padding-top: 10px;
                .frequencyBtn{
                    background: #FFFFFF;
                    border: 0.5px solid #E1E1E1;
                    border-radius: 5px;          
                    width: 47%;          
                    text-align: center;
                    padding: 10px 0px;
                    color:#777777;
                    font-size: 14px;
                }
                .active {
                    background-color: #6369D1;
                    color: white;
                    font-weight: bold;
                }
            }
        }
        
    }
    &__submit {
        button {
            margin: 20px auto;
            display: block;
            width: 70%;
            background: #6369D1;
            border-radius: 8px;
            color: white;
            font-family: 'Poppins', sans-serif;
            font-weight: bold;
            border: none;
            font-size: 16px;
            padding: 8px 15px;
            height: 50px;
        }
    }
    .disabledBtn {
        button {
            background: #c1c4f1;
            color: white;
        }
    }
}