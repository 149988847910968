.homepage {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-top: 40px;
    max-width: 100%;
    margin: 0 auto;
    gap: 90px;
}

.profileStats {
    min-width: 300px;
}

.feed{
    width: 800px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    justify-content: center;
    align-items: center;
}

.create{
    width: 300px;
    margin-left: 40px;
    display: flex;
    flex-direction: column;
    gap: 30px;
    button {
        width: 100%;
        border: none;
        background: #6369D1;
        border-radius: 6px;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 116.5%;
        text-align: center;
        color: #FFFFFF;
        height: 40px;
        margin: 0 auto;
        display: block;

    }

    .subscriberFeed {
        background: #FFFFFF;
        border: 0.5px solid #DEDEDE;
        border-radius: 6px;
        max-height: 500px;
        // min-height: 400px;
        display: flex;
        flex-direction: column;
        padding-top: 10px;
        h3 {
            padding: 10px 20px;
            margin: 0;
            font-family: 'Poppins';
        }
        .feedItem {
            padding: 15px 20px;
            display: flex;
            flex-direction: column;
            gap: 8px;
            transition: all 0.3s;
            p {
                margin: 0;
                font-family: 'Roboto';
                font-size: 14px;
                line-height: 20px;
                span {
                    font-weight: bold;
                    color: #6369D1;
                }
            }
            h6{
                margin: 0;
                color: #797979;
                font-family: 'Roboto';
            }
            &:hover {
                background-color: #DEDEDE;
            }
        }
        .showMore {
            h4 {
                text-align: center;
                color: #797979;            
                transition: all 0.3s;
                &:hover {
                    color: black;
                }
            }
        }
    }
}