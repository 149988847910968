.subscriberCard{
    padding-top: 40px;
    &__dp {
        padding-top: 40px;;
        img {
            width: 150px;
            margin: 0 auto;
            display: block;
            border-radius: 50%;
            border: 1px solid #d1d1d1
        }
        h4 {
            text-align: center;
            font-size: 25px;
            margin-top: 15px;
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 700;
            line-height: 116.5%;
             letter-spacing: -0.05em;
            color: #333;
        }
    }
    &__stats{
        display: flex;
        justify-content: center;
        margin-bottom: 30px;
        .statItem{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            min-width: 30%;
            h4 {
                font-size: 25px;
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 600;
                line-height: 116.5%;
                text-align: center;
                color: #333;
                margin-bottom: 0px;
                margin-top: 5px;
            }
            h5 {
                margin-top: 5px;
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 700;
                font-size: 15px;
                line-height: 116.5%;
                /* or 10px */

                text-align: center;

                color: #6369D1;
            }
        }
    }
    &__buttons{
        button{
            margin: 20px auto;
            display: block;
            width: 70%;
            background: #6369D1;
            border-radius: 8px;
            color: white;
            font-family: 'Poppins', sans-serif;
            font-weight: bold;
            border: none;
            font-size: 16px;
            padding: 8px 15px;
            height: 50px;
        }
    }
}