.manageAccount {
    // padding: 0px 8rem;
    width: 90%;
    margin: 0 auto;
    .container {
        padding: 40px;
        .breadcrumb {
            margin: 0px;
            margin-bottom: 8px;
            &:hover {
                text-decoration: underline;
            }
        }
        .header{
            display: flex;
            justify-content: space-between;
            .sectionName{
                display: flex;
                flex-direction: column;
                h3 {
                    font-family: 'Poppins';
                    margin: 0;
                    font-size: 30px;
                }
                p {
                    margin: 0;
                    font-family: 'Roboto';
                    color: #797979;
                }
            }
            .stats {
                display: flex;
                gap: 80px;
                .items {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    h3 {
                        font-family: 'Poppins';
                        margin: 0;
                        font-size: 30px;
                    }
                    p {
                        margin: 0;
                        font-family: 'Roboto';
                        color: #797979;
                        font-weight: bold;
                    }
                }
            }
        }
        .body {
            .viewToggle {
                padding-top: 25px;
                display: flex;
                flex-direction: row;
                gap: 20px;
                button {
                    background: white;
                    border-radius: 8px;
                    color: #797979;
                    font-family: "Roboto", sans-serif;
                    font-weight: bold;
                    border: 1px solid #E2E2E2;
                    padding: 12px 15px;
                }
                .active {
                    background: #6369D1;
                    color: white;
                    border: none;
                }
            }
            .userList {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                padding-top: 40px;
                gap: 35px;
                justify-content: space-around;
                .userCard {
                    min-width: 400px;
                    display: flex;
                    border: 1px solid #DEDEDE;
                    border-radius: 8px;
                    padding: 20px 25px;
                    justify-content: center;
                    gap: 20px;
                    img {
                        width: 90px;
                        height: 90px;
                        border-radius: 50px;
                    }
                    .details {
                        display: flex;
                        flex-direction: row;
                        gap: 60px;
                        .profileDetails {
                            display: flex;
                            flex-direction: column;
                            gap: 25px;
                            .name {
                                display: flex;
                                flex-direction: column;
                                gap: 8px;
                                h4 {
                                    font-family: 'Roboto';
                                    margin: 0;
                                }
                                p{
                                    margin: 0;
                                    font-size: 14px;
                                    font-family: 'Roboto';
                                    color: #797979;                                }
                            }
                            .visitProfile {
                                button {
                                    background-color: white;
                                    border: 0.5px solid #797979;
                                    color: #797979;
                                    font-family: 'Roboto';
                                    padding: 8px 12px;
                                    border-radius: 8px;
                                }
                            }
                        }
                        .earningDetails {
                            display: flex;
                            flex-direction: column;
                            gap: 25px;
                            text-align: right;
                            .amount {
                                display: flex;
                                flex-direction: column;
                                gap: 5px;
                                h4 {
                                    font-family: 'Roboto';
                                    color: #6369D1;
                                    margin: 0;
                                    font-weight: bold;
                                    font-size: 20px;
                                }
                                p{
                                    margin: 0;
                                    font-size: 14px;
                                    font-family: 'Roboto';
                                    color: #797979;
                                }
                            }

                            .earning {
                                display: flex;
                                flex-direction: column;
                                gap: 5px;
                                h4 {
                                    font-family: 'Roboto';
                                    color: #6369D1;
                                    margin: 0;
                                    font-weight: bold;
                                    font-size: 16px;
                                }
                                p{
                                    margin: 0;
                                    font-size: 12px;
                                    font-family: 'Roboto';
                                    font-weight: bold;
                                    color: black;                                }
                            }
                        }
                    }
                }
            }
        }
    }
}