@mixin ctaButton {
    background: linear-gradient(180deg, #646BE2 31.3%, rgba(104, 60, 197, 0.6) 100%);
    box-shadow: 0px 5px 8px rgba(0, 0, 0, 0.14);
    border-radius: 8px;
    border: none;
    height: 46px;
    min-width: 320px;
    color: white;
    font-family: 'Poppins';
    font-weight: bold;
    font-size: 16px;
}