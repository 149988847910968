.navbar {
    border-bottom: 1px solid #DEDEDE;
    &__container {
        display: flex;
        justify-content: space-between;
        padding: 0 135px;
        min-height: 80px;

        .nav-left {
            display: flex;
            align-items: center;
            h3 {
                font-family: 'Poppins', sans-serif;
                font-weight: bold;
                margin: 0;
            }
        }
        .nav-right {
            display: flex;
            align-items: center;
            gap: 10px;
            .walletConnection {
                display: flex;
                align-items: center;
                .ctwBtn {
                    background: #6369D1;
                    border-radius: 8px;
                    color: white;
                    font-family: 'Poppins', sans-serif;
                    font-weight: bold;
                    border: none;
                    padding: 12px 15px;
                }
                .connectedState {
                    text-align: right;
                    margin-right: 20px;
                    border: 1px solid #efefef;
                    border-radius: 8px;
                    padding: 5px 11px;
                    font-family: 'Roboto';
                    h4 {
                        margin: 0px;
                        font-size: 12px;
                        display: flex;
                        justify-content: end;
                        color: #6369D1;
                        span {
                            height: 8px;
                            width: 8px;
                            background-color: #81C271;
                            margin-top: 3px;
                            margin-left: 5px;
                            border-radius: 10px;
                        }
                    }
                    p{
                        margin: 0px;
                        max-width: 200px;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        font-size: 14px;
                        font-weight: bold;
                    }
                }
            }
            .hamburgerMenu {
                position: relative;
                min-height: 80px;
                display: flex;
                width: 50px;
                justify-content: center;
                .dropdown-content {
                    visibility: hidden;
                    display: flex;
                    flex-direction: column;
                    position: absolute;
                    background-color: #f9f9f9;
                    min-width: 175px;        
                    box-shadow: 6px 4px 20px 0px rgb(0 0 0 / 11%);
                    z-index: 11;
                    right: -10px;
                    top: 100%;
                    border: 1px solid #DEDEDE;
                    a {
                        padding: 16px;
                        font-family: 'Roboto';
                        font-size: 14px;
                        color: black;
                        text-decoration: none;
                        border-bottom: 1px solid #DEDEDE;
                        &:last-child {
                            border-bottom: none;
                        }
                        &:hover {
                            background-color: #efefef;
                        }
                    }
                    &:hover {
                        display: flex;
                        flex-direction: column;
                    }
                  }
                &:hover {
                    box-shadow: 0px -3px 0px 0px #6369D1 inset;
                    .dropdown-content {
                        visibility: visible;    
                    }
                }
            }
        }
    }
}